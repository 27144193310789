
import { defineComponent } from "vue";

import CargoGroupCatalogue from "@/components/catalogues/CargoGroup/CargoGroupCatalogue.vue";

export default defineComponent({
  components: { CargoGroupCatalogue },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
